
import {
  getFirestore,
  doc,
  updateDoc,
  Timestamp,
  query,
  collection, where, getDocs, getDoc, deleteDoc
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

//Store/State tiedosto joka vastaa tietokannan päivittämisestä

const actions = {
  //Action joka päivittää käyttäjän salasana kutsumalla backend funktio
  async updatePass({}, payload) {
    const urlData = 'https://europe-west1-ows-webedi-prod.cloudfunctions.net/app/api/updatepass'
    const bodyData = JSON.stringify({
      uid: payload.uid,
      password: payload.pass
    });
    const bodylength = bodyData.toString().length
    await fetch(urlData, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+ payload.token,
        'Content-Type': 'application/json',
        'Content-Length': bodylength,
        'Host': 'europe-west1-ows-webedi-prod.cloudfunctions.net',
        'Accept-Encoding': 'gzip,deflate,br',
        'Connection': 'keep-alive'
      },
      body: bodyData
    }).then(response => console.log(response.json()))
  },
  //Action joka päivittää käyttähän tiedot tietokannassa
  async updateUser ({}, payload) {
    const db = getFirestore()
    const q = query(collection(db, "users"),
      where("username", "==", payload.username))
    const querySnapshot = await getDocs(q)
    const uid = querySnapshot.docs[0].id
    const user = doc(db, "users", uid)

    await updateDoc(user, {
      email: payload.email,
      firstname: payload.firstname,
      lastname: payload.lastname,
      phone: payload.phone,
      disabled: payload.disabled
    })
  },

  async updateUserHeaders ({}, payload) {
    const auth = await getAuth()
    const db = await getFirestore()

    const user = doc(db, "users", auth.currentUser.uid)
    await updateDoc(user, {
      headers: payload.headers
    })
  },
  async updateUserOrdersHeaders ({}, payload) {
    const auth = await getAuth()
    const db = await getFirestore()

    const user = doc(db, "users", auth.currentUser.uid)
    await updateDoc(user, {
      ordersHeaders: payload.headers
    })
  },

  //Action joka päivittää käyttäjän lokalisaatio
  async updateLocalization({commit}, payload) {
    const auth = await getAuth()
    const db = await getFirestore()

    const user = doc(db, "users", auth.currentUser.uid)
    await updateDoc(user, {
      lang: payload.lang
    })
    return true;
  },


  //Action joka päivittää tilauksen tiedot tietokannassa
  async updateOrder ({}, payload) {
    const db = getFirestore()
    const orderRef = doc(db, "orders", payload.orderid)

    for(let i = 0; i < payload.rows.length; i++) {
      payload.rows[i].deliverydate = Timestamp.fromDate(new Date(payload.rows[i].deliverydate.seconds * 1000))
      payload.rows[i].confirmed_amount = parseFloat(payload.rows[i].confirmed_amount).toFixed(4)
      payload.rows[i].order_amount = parseFloat(payload.rows[i].order_amount).toFixed(4)
      if(payload.rows[i].delivery_amount) {
        payload.rows[i].delivery_amount = parseFloat(payload.rows[i].delivery_amount).toFixed(4)
      }
    }
    payload.confirmeddeliverydate = Timestamp.fromDate(new Date(payload.confirmeddeliverydate.seconds * 1000))
    await updateDoc(orderRef, {
      ordernotes: payload.ordernotes,
      confirmeddeliverydate: payload.confirmeddeliverydate,
      orderrows: payload.rows
    });
  },
  //Action joka päivittää organisaation tiedot tietokannassa
  async updateOrg ({}, payload) {
    const db = getFirestore()
    const q = query(collection(db, "organisations"),
      where("partyid", "==", payload.oldpartyid))
    const querySnapshot = await getDocs(q)
    const oid = querySnapshot.docs[0].id
    const organisation = doc(db, "organisations", oid)
    let dborgtype = (payload.orgtype == 'Tilaaja') ? 'purchaser' : 'supplier'
    await updateDoc(organisation, {
      name: payload.name,
      orgtype: dborgtype,
      partyid: payload.newpartyid,
      info: payload.info,
    })
  },
  //Action joka päivittää tilauksen tila
  async updateStatus ({}, payload) {
    const db = getFirestore()
    const orderRef = doc(db, "orders", payload.orderid)

    if (payload.ordermanager) {
      await updateDoc(orderRef, {
        orderstatus: payload.orderstatus,
        ordermanager: payload.ordermanager
      });
    } else {
      await updateDoc(orderRef, {
        orderstatus: payload.orderstatus
      });
    }
  },

  async updateNotifications({}, payload) {
    const db = getFirestore()
    const q = query(collection(db, "notifications"),
      where("title", "==", payload.title))
    const querySnapshot = await getDocs(q)
    const id = querySnapshot.docs[0].id

    await deleteDoc(doc(db, "notifications", id));
  },

  //Action joka nollaa tilauksen timestampit
  async dropTimestamps ({}, payload) {
    const db = getFirestore()
    const orderRef = doc(db, "orders", payload.orderid)
    await updateDoc(orderRef, {
      confirmed_timestamp: false,
      delivered_timestamp: false
    })
  },
}

export default {
  namespaced: true,
  actions,
}
