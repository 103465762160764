import {getFirestore, orderBy, startAfter, limit, doc, getDoc, query, getDocs, collection, where } from "firebase/firestore";

//Store/State tiedosto joka vastaa taulukkoiden datan hausta

const state = {
  orders: [],
  order: null,
  organisations: [],
  neworders: [],
  notifications: [],
  users: [],
  lastVisible: null,
  allLoaded: false,
  isLoading: false,
}

const mutations = {
  setData(state, data) {
    if (data.length) {
      state.orders = data.sort((a, b) => b.deliverydate - a.deliverydate);
      state.neworders = data.filter(obj => (obj.orderstatus == 'new' || obj.orderstatus == 'open')).sort((a, b) => b.createdAt - a.createdAt);
    } else {
      state.orders = []
    }
  },

  addData(state, chunk) {
    state.orders = [...state.orders, ...chunk];
    state.neworders = [...state.neworders, ...chunk.filter(obj => (obj.orderstatus == 'new' || obj.orderstatus == 'open')).sort((a, b) => b.createdAt - a.createdAt)]
  },
  setAllLoaded(state, value) {
    state.allLoaded = value;
  },
  setLastVisible(state, doc) {
    state.lastVisible = doc;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  resetData(state) {
    state.orders = [];
    state.allLoaded = false;
    state.lastVisible = null;
    state.isLoading = false;
  },
  setNotifications(state, notififications_data) {
    state.notifications = notififications_data
  },
  setOrder(state, order_data) {
    state.order = order_data
  },
  setUsers(state, users_data) {
    state.users = users_data
  },
  setOrganisations(state, organisations_data) {
    state.organisations = organisations_data
  }
}

const actions = {
  //Action joka hakea dataa tietokannasta tietylle käyttäjälle
  async fetchData ({state, commit }, partyID) {
    if(state.orders.length == 0) {
      commit('setData', '')
      const db = getFirestore()
      let q = query(collection(db, "orders"), where("supplierid", "==", partyID));
      let querySnapshot = await getDocs(q);
      if(!querySnapshot.docs.length){
        q = query(collection(db, "orders"), where("customerid", "==", partyID));
        querySnapshot = await getDocs(q);
      }
      let data = []
      querySnapshot.forEach((doc) => {
        const obj = doc.data()
        data.push(obj)
      })
      if (data.length) {
        commit('setData', data)
      }
    }
  },

  async fetchAdminOrders({ commit, state }) {
    if (state.allLoaded || state.isLoading) {
      return;
    }

    commit('setIsLoading', true);

    const db = await getFirestore();
    const pageSize = 100;

    let localLastVisible = state.lastVisible;
    let allLoaded = false;

    try {
      while (!allLoaded) {
        let q;

        if (localLastVisible) {
          q = query(
            collection(db, "orders"),
            orderBy("orderdate", "desc"),
            startAfter(localLastVisible),
            limit(pageSize)
          );
        } else {
          q = query(
            collection(db, "orders"),
            orderBy("orderdate", "desc"),
            limit(pageSize)
          );
        }

        const snapshot = await getDocs(q);

        if (snapshot.empty) {
          allLoaded = true;
          commit('setAllLoaded', true);
          break;
        }

        const chunk = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        commit('addData', chunk);
        localLastVisible = snapshot.docs[snapshot.docs.length - 1];
        commit('setLastVisible', localLastVisible);

        await new Promise(resolve => setTimeout(resolve, 50));
      }
    } catch (error) {
      console.error("Loading error:", error);
    } finally {
      commit('setIsLoading', false);
    }
  },

  //Action joka hakee käyttäjiä tietokannasta
  async fetchUsers ({ commit }) {
    const db = await getFirestore()
    const querySnapshotUsers = await getDocs(collection(db, "users"));
    const querySnapshotOrgs = await getDocs(collection(db, 'organisations'))

    let usersData = []
    let orgsData = []


    querySnapshotUsers.forEach(( doc) => {
      const obj = doc.data()
      obj.id = doc.id
      usersData.push(obj)
    })

    querySnapshotOrgs.forEach((doc) => {

      const obj = doc.data()
      obj.id = doc.id
      //const orgid = obj.organisation.path.replace("organisations/", "")
      orgsData.push(obj)
    })

    for(let i = 0; i < usersData.length; i++) {
      for(let j = 0; j < orgsData.length; j++) {
        if (usersData[i].organisation.id == orgsData[j].id) {
          usersData[i].organisation = orgsData[j]
        }
      }
    }

    if (usersData.length) {
      commit('setUsers', usersData)
    }
  },

  async fetchNofitications({commit}) {
    const db = getFirestore()
    const q = query(collection(db, "notifications"));
    const querySnapshot = await getDocs(q);
    let data = []
    querySnapshot.forEach((doc) => {
      const obj = doc.data()
      data.push(obj)
    })
    if (data.length) {
      data.sort(function(a, b) {
        return b.date - a.date;
      });
      commit('setNotifications', data)
    }
  },

  //Action joka hakea tietty orderi ID perusteella
  async fetchOrder ({state, commit }, id) {
    if(!state.order) {
      const db = await getFirestore()
      const docRef = doc(db, "orders", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        commit('setOrder', docSnap.data())
      }
    }
  },
  //Action joka hakea kaikki organisaatiot
  async fetchOrganisations ({state, commit }) {
    if(state.organisations.length == 0) {
      const db = getFirestore()
      const q = query(collection(db, "organisations"));
      const querySnapshot = await getDocs(q);
      let data = []
      querySnapshot.forEach((doc) => {
        const obj = doc.data()
        data.push(obj)
      })
      if (data.length) {
        commit('setOrganisations', data)
      }
    }
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
